<template>
    <div>
        <b-card bg-variant="Success" class="text-center" v-if="submitted">
            <b-button
            variant="outline-primary"
            :to="{ name: 'crm-utenti-index' }"
            >
                <feather-icon
                    icon="CornerUpLeftIcon"
                    size="16"
                    class="mr-1"
                />Lista Utenti
            </b-button>
        </b-card>

        <b-card border-variant="danger" v-if="errore_caricamento">
          <b-row class="match-height">
            <b-col
              md="8"
            >
              <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
              <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
            </b-col>
            <b-col 
              md="4"
              class="text-center"
            >
              <b-button
              variant="primary"
              :to="{ name: 'crm-utenti-index' }"
              >
                  <feather-icon
                      icon="CornerUpLeftIcon"
                      size="16"
                      class="mr-1"
                  />Lista Utenti
              </b-button>
            </b-col>
          </b-row>
            
        </b-card>

        <div class="text-center my-3" v-if="Caricato == false">
            <b-spinner  v-if="errore_caricamento == false"
                variant="primary"
                style="width: 3rem; height: 3rem;"
                label="loading"
            />
            <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
        </div>

        <div v-if="Caricato">
          <validation-observer ref="simpleRules">
            <b-form>
              <b-tabs pills>
                <b-tab active>
                  <template #title>
                    <feather-icon
                      icon="UserIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Dettagli</span>
                  </template>
                  <b-card bg-variant="Default">
                    <b-row>
                      <b-col md="4" lg="3">
                        <b-form-group label="Nome *" label-for="name">
                            <validation-provider
                            #default="{ errors }"
                            name="Nome"
                            rules="required"
                            >
                            <b-form-input
                                v-model="campiform.user.name"
                                :state="errors.length > 0 ? false:null"
                                placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Cognome *" label-for="Surname">
                            <validation-provider
                            #default="{ errors }"
                            name="Cognome"
                            rules="required"
                            >
                            <b-form-input
                                v-model="campiform.user.surname"
                                :state="errors.length > 0 ? false:null"
                                placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="4" lg="3">
                        <b-form-group label="Email *" label-for="email">
                            <validation-provider
                            #default="{ errors }"
                            name="Email dell'utente"
                            rules="required|email"
                            >
                            <b-form-input
                                v-model="campiform.user.email"
                                :state="errors.length > 0 ? false:null"
                                placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Password *" label-for="Password">
                          <validation-provider
                            #default="{ errors }"
                            name="Password"
                            rules="required"
                          >
                            <b-form-input
                              type="password"
                              v-model="campiform.user.password"
                              :state="errors.length > 0 ? false:null"
                              placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Telefono" label-for="telefono">
                            <b-form-input
                                v-model="campiform.user.phone"
                                placeholder=""
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Cellulare" label-for="Cellulare">
                            <b-form-input
                                v-model="campiform.user.mobile_phone"
                                placeholder=""
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Sesso *" label-for="gender_type_id">
                            <b-form-select
                                v-model="campiform.user.gender_type_id"
                                :options="options_gender"
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Ruolo *" label-for="role_id">
                            <validation-provider
                            #default="{ errors }"
                            name="Ruolo"
                            rules="required"
                            >
                            <b-form-select
                                v-model="campiform.user.role_id"
                                :options="options_role"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                  </b-card>
                </b-tab>

                <b-tab>
                  <template #title>
                    <feather-icon
                      icon="InfoIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Contratto</span>
                  </template>
                  <b-card bg-variant="Default">
                    <b-row>
                      <b-col md="2" lg="4">
                        <b-form-group label="Cassa" label-for="cash_id">
                          <b-form-select
                            v-model="campiform.contratto.cash_id"
                            :options="options_cassa"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="2" lg="4">
                        <b-form-group label="Ritenuta d'acconto" label-for="withholding_tax_id">
                          <b-form-select
                            v-model="campiform.contratto.withholding_tax_id"
                            :options="options_ritenuta_acconto"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col md="2" lg="4">
                        <b-form-group label="IBAN" label-for="iban">
                          <b-form-input
                            v-model="campiform.contratto.iban"
                            placeholder=""
                          />
                        </b-form-group>                        
                      </b-col>
                      <b-col md="2" lg="4">
                        <b-form-group label="BIC" label-for="bic">
                          <b-form-input
                            v-model="campiform.contratto.bic"
                            placeholder=""
                          />
                        </b-form-group>                        
                      </b-col>
                      <b-col md="2" lg="4">
                        <b-form-group label="Banca" label-for="bank">
                          <b-form-input
                            v-model="campiform.contratto.bank"
                            placeholder=""
                          />
                        </b-form-group>                        
                      </b-col>
                      <b-col md="2" lg="4">
                        <b-form-group label="€ Fisso Mensile" label-for="fixed_monthly">
                          <b-form-input
                            type="number"
                            v-model="campiform.contratto.fixed_monthly"
                            placeholder=""
                          />
                        </b-form-group>                        
                      </b-col>
                      <b-col md="2" lg="4">
                        <b-form-group label="% Provvigioni Sulle Vendite (Cliente nuovo)" label-for="sale_commissions_new">
                          <b-form-input
                            type="number"
                            v-model="campiform.contratto.sale_commissions_new"
                            placeholder=""
                          />
                        </b-form-group>
                        </b-col>
                      <b-col md="2" lg="4">
                        <b-form-group label="% Provvigioni Sulle Vendite (Cliente vecchio)" label-for="sale_commissions_old">
                          <b-form-input
                            type="number"
                            v-model="campiform.contratto.sale_commissions_old"
                            placeholder=""
                          />
                        </b-form-group>                        
                      </b-col>
                      <b-col md="2" lg="4">
                        <b-form-group label="% Gettone immediato alla vendita (Cliente nuovo)" label-for="sale_token_new">
                          <b-form-input
                            type="number"
                            v-model="campiform.contratto.sale_token_new"
                            placeholder=""
                          />
                        </b-form-group>                        
                      </b-col>
                      <b-col md="2" lg="4">
                        <b-form-group label="% Gettone immediato alla vendita (Cliente vecchio)" label-for="sale_token_old">
                          <b-form-input
                            type="number"
                            v-model="campiform.contratto.sale_token_old"
                            placeholder=""
                          />
                        </b-form-group>                        
                      </b-col>
                      

                    </b-row>
                  </b-card>
                </b-tab>
              </b-tabs>

              <b-row>
                  <b-col md="12" class="text-center">
                  <b-button
                    variant="primary"
                    type="submit"
                    :disabled="action_press"
                    @click.prevent="validationForm()"
                  >
                    <div v-if="action_press">Salvaggio in corso <b-spinner small class="ml-1" /></div>
                    <div v-else>Salva</div> 
                  </b-button>
                  </b-col>
              </b-row>
              
            </b-form>
          </validation-observer>
        </div>
          
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BSpinner, BButton, BFormGroup, BForm, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, digits, alphaDash, length, password,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BSpinner, 
    BCardText, 
    BButton, 
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,     
  },
  data() {
    return {
      campiform: {
        user: {
          name: '',
          surname: '',
          email: '', 
          password: '', 
          phone: '', 
          mobile_phone: '',
          gender_type_id: '1',
          role_id: '',
        },
        contratto: {
          iban: '',
          bank: '',
          bic: '',
          fixed_monthly: '',
          sale_commissions_new: '',
          sale_commissions_old: '',
          sale_token_new: '',
          sale_token_old: '',
          cash_id: 1,
          withholding_tax_id: 1,
        },
      },
      required,
      email,
      password, 
      alphaDash, 
      digits,
      length,
      options_gender: [ ],
      options_role: [ ],
      options_cassa: [ ],
      options_ritenuta_acconto: [ ],
      Caricato: false,
      errore_caricamento: false,
      submitted: false,
      action_press: false,
        
    }
  },
  created() {
    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })

    const genderPromise = this.$http.get('api/fs/gender/list')
    const rolePromise = this.$http.get('api/fs/role/list')
    const cassaPromise = this.$http.get('api/fs/employee/cash/list')
    const ritenutaAccontoPromise = this.$http.get('api/fs/employee/withholding-tax/list')

    Promise.all([genderPromise, rolePromise, cassaPromise, ritenutaAccontoPromise]).then(response => {
      if(response[0].data.statusCode===200){
        //caricamento lista gender
        this.options_gender = response[0].data.reply
        
        if(response[1].data.statusCode===200){
          //caricamento lista ruoli
          this.options_role = response[1].data.reply

          if(response[2].data.statusCode===200){
            //caricamento lista cassa
            this.options_cassa = response[2].data.reply

            if(response[3].data.statusCode===200){
              //caricamento lista ritenuta acconto
              this.options_ritenuta_acconto = response[3].data.reply

              this.Caricato = true;
              this.errore_caricamento = false;
            } else {
              this.Caricato = false;
              this.errore_caricamento = true;
            } 
          } else {
            this.Caricato = false;
            this.errore_caricamento = true;
          }
        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }
      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
        
    })
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //console.log(this.campiform)
          this.action_press = true
          
          this.$http.post('api/crm/employee/card', 
            this.campiform.contratto
            ).then(response => { 
              console.log("risposta server --------")
              console.log(response.data)

              if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                if(response.data.reply.employeeData.id > 0){
                  //salva i dati user
                  this.$http.post('api/crm/user/employee/card/'+response.data.reply.employeeData.id, 
                  this.campiform.user
                  ).then(response_user => {
                    if((response_user.data.statusCode>=200) && (response_user.data.statusCode<=299)){
                      //risposta positiva
                      this.$router.replace('/crm/utenti')
                        .then(() => {
                          this.$swal({
                              icon: 'success',
                              title: 'Nuovo utente creato correttamente',
                              confirmButtonText: 'chiudi',
                              customClass: {
                              confirmButton: 'btn btn-success',
                              },
                          })
                        })                    

                    } else {
                      //risposta negativa (errore sul server)
                      if(response.data.reply.message != ''){
                        this.$swal({
                            icon: 'warning',
                            title: 'Si è verificato un errore',
                            text: response_user.data.reply.message,
                            customClass: {
                            confirmButton: 'btn btn-outline-primary',
                            },
                        })
                      } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Si è verificato un errore generico',
                            text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                            customClass: {
                            confirmButton: 'btn btn-outline-primary',
                            },
                        })
                      }

                      this.action_press = false
                    }
                  
                  }).catch(e => {
                    console.log(e);
                  }); 
                } else {
                  //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                  this.$swal({
                      icon: 'warning',
                      title: 'Si è verificato un errore',
                      text: 'Salvataggio riuscito ma con errore '+response.data.statusCode+', ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })

                  this.action_press = false
                }
                
              } else {
                //risposta negativa (errore sul server)
                if(response.data.reply.message != ''){
                  this.$swal({
                      icon: 'warning',
                      title: 'Si è verificato un errore',
                      text: response.data.reply.message,
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                } else {
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore generico',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }

                this.action_press = false
              }
            }).catch(e => {
              console.log(e);
            });
          
        }
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
